import React from 'react';
import './App.css';

function App() {
  return (
    <div className="coming-soon-container">
      <header className="header-container">
        <span className="header-part">The Tech</span>
        <span className="header-gradient">Traveller</span>
        <span className="header-part">Guy's Blog</span>
      </header>
      <h1 className="coming-soon-text">Coming Soon</h1>
    </div>
  );
}

export default App;
